.form-layer{
  -webkit-overflow-scrolling: touch;
  background-color: var(--color-white);
  border-radius: 4px;
  box-shadow: 0 0 15px var(--shadow);
  box-sizing: border-box;
  left: 50%;
  height: 100%;
  overflow-y: auto;
  padding: 55px 2% 21px 2%;
  position: fixed;
  top: 50%;
  transform: translate(75%, -50%);
  transition: transform .5s;
  width: 100%;
  z-index: 201;

  @media (min-width: 768px) and (max-width: 959px) {
    max-height: 90vh;
    max-width: 90%;
  }

  @media (min-width: 960px) {
    max-height: 95vh;
    max-width: 70%;
    padding-left: 5%;
    padding-right: 5%;
  }
}

.form-layer--visible{
  transform: translate(-50%, -50%);
}

.form-cover{
  background-color: var(--overlay);
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 200;
}

.form-cover--visible{
  display: block;
}

.form-header{
  text-align: center;
}

.is-required{
  color: var(--color-red);
  font-weight: 700;
}

.form{
  padding: 30px 5%;
}

.form__row--columns{
  @media (min-width: 768px) {
    display: grid;
    grid-column-gap: 5%;
    grid-template-columns: 1fr 1fr;
  }
}

.form__element{
  padding-bottom: 21px;
}

.form__label{
  display: block;
  margin-bottom: 6px;
}

.form__text{
  border: 1px solid var(--color-light-grey);
  border-radius: 2px;
  box-sizing: border-box;
  height: 35px;
  outline: none;
  padding: 9px;
  transition: border-color .3s;
  width: 100%;

  &:hover,
  &:focus{
    border-color: var(--color-grey);
  }
}

.form__text--area{
  height: auto;
  resize: none;
}

.form__help{
  display: inline-block;
  font-size: 0.75rem;
  margin-top: 6px;
}

.form__accepted{
  margin-bottom: 12px;
}

.form__actions{
  margin-top: 21px;
  text-align: center;
}

.form__actions--hidden{
  display: none;
}

.form-close{
  background: none;
  border: none;
  outline: none;
  position: absolute;
  right: 2%;
  top: 2%;
}

.form-message{
  border: 1px solid var(--color-black);
  border-radius: 4px;
  display: none;
  padding: 12px 3%;
  text-align: center;
}

.form-message--ok{
  border-color: var(--color-blue-dark);
  color: var(--color-blue-dark);
  display: block;
}

.form-message--no{
  border-color: var(--color-red);
  color: var(--color-red);
  display: block;
}
