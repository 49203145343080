:root{
  --color-black: #303030;
  --color-light-blue: rgba(54, 97, 236, .1);
  --color-blue: #3661EA;
  --color-blue-dark: #15359E;
  --color-white: #fff;
  --color-grey: #949494;
  --color-light-grey: #D2D2D2;
  --color-green: #639E1C;
  --color-red: #CB0F0F;
  --font-primary: 'CH', Arial, Helvetica, sans-serif;
  --font-secondary: 'SK', Arial, Helvetica, sans-serif;
  --shadow: rgba(0, 0, 0, .1);
  --overlay: rgba(48, 48, 48, .5);
}
