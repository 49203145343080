/* html5doctor.com Reset v1.6.1 - http://cssreset.com */
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

:root {
  --color-black: #303030;
  --color-light-blue: rgba(54, 97, 236, .1);
  --color-blue: #3661EA;
  --color-blue-dark: #15359E;
  --color-white: #fff;
  --color-grey: #949494;
  --color-light-grey: #D2D2D2;
  --color-green: #639E1C;
  --color-red: #CB0F0F;
  --font-primary: "CH", Arial, Helvetica, sans-serif;
  --font-secondary: "SK", Arial, Helvetica, sans-serif;
  --shadow: rgba(0, 0, 0, .1);
  --overlay: rgba(48, 48, 48, .5);
}

body {
  color: var(--color-black);
  font-family: var(--font-primary);
  font-size: 100%;
  -webkit-text-size-adjust: none;
}

h1, h2, h3, h4 {
  font-family: var(--font-secondary);
}

p {
  line-height: 1.3;
}
p:not(:last-of-type) {
  margin-bottom: 21px;
}

.is-blue-text {
  color: var(--color-blue);
}

.is-dark-blue-text {
  color: var(--color-blue-dark);
}

.is-nav-link {
  color: inherit;
  font-weight: 700;
  text-decoration: none;
  transition: color 0.3s;
}
.is-nav-link:hover, .is-nav-link:focus {
  color: var(--color-blue);
}
@media (min-width: 768px) {
  .is-nav-link {
    font-size: 1.125rem;
  }
}

.is-nav-link--accent {
  text-transform: uppercase;
}

@media (min-width: 960px) {
  .is-flex {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}

.is-flex--inverted {
  flex-direction: row-reverse;
}

.box-content {
  box-sizing: border-box;
  padding-left: 3%;
  padding-right: 3%;
}
@media (min-width: 768px) {
  .box-content {
    padding-left: 2%;
    padding-right: 2%;
  }
}

/*.main-content {
  padding-top: 120px;
}*/
.main-title {
  font-size: 2.375rem;
}
@media (min-width: 768px) {
  .main-title {
    font-size: 3.75rem;
  }
}

.main-subtitle {
  font-size: 1.125rem;
}
@media (min-width: 768px) {
  .main-subtitle {
    font-size: 1.5rem;
  }
}

.section-title {
  font-size: 2rem;
}
@media (min-width: 768px) {
  .section-title {
    font-size: 3rem;
  }
}

.section-title--spaced {
  margin-bottom: 15px;
}

@media (min-width: 960px) {
  .section-header {
    padding-left: 15%;
    padding-right: 15%;
    text-align: center;
  }
}

.section-description {
  font-size: 1.125rem;
}
@media (min-width: 960px) {
  .section-description {
    font-size: 1.3125rem;
  }
}

@media (min-width: 960px) {
  .section-description--short {
    padding-left: 15%;
    padding-right: 15%;
  }
}

.section-cta {
  margin-top: 82px;
}

.section-cta--solo {
  text-align: center;
}

.section-art {
  height: auto;
  width: 100%;
}
@media (min-width: 768px) {
  .section-art {
    width: 45vw;
  }
}

.intro {
  border-bottom: 1px solid var(--color-light-grey);
  padding: 90px 5% 60px 5%;
  position: relative;
  text-align: center;
}
@media (min-width: 768px) {
  .intro {
    padding: 200px 20% 140px 20%;
  }
}

.main-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  left: 0;
  padding-top: 21px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 102;
}
@media (min-width: 768px) {
  .main-header {
    padding-top: 36px;
  }
}

@media (max-width: 767px) {
  .main-header__logo {
    height: 24px;
    width: auto;
  }
}

.main-footer {
  padding-bottom: 30px;
  padding-top: 30px;
}

.assets-credits {
  font-size: 0.8125rem;
}
.assets-credits a {
  color: var(--color-blue-dark);
}
@media (min-width: 768px) {
  .assets-credits {
    font-size: 0.875rem;
  }
}

.footer-menu {
  display: flex;
}
@media (max-width: 767px) {
  .footer-menu {
    margin-bottom: 6px;
  }
}

.footer-menu__item {
  list-style: none;
}

.footer-menu__link {
  padding-right: 18px;
}

.button {
  border: 2px solid var(--color-blue);
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  font-size: 0.875rem;
  font-style: italic;
  font-weight: 700;
  outline: none;
  padding: 12px 18px;
  text-decoration: none;
  text-transform: uppercase;
}
.button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.button + .button {
  margin-left: 6px;
}
@media (min-width: 768px) {
  .button {
    font-size: 0.9375rem;
    padding-left: 36px;
    padding-right: 36px;
  }
  .button + .button {
    margin-left: 12px;
  }
}

.button--filled {
  background-color: var(--color-blue);
  color: var(--color-white);
  transition: background-color 0.3s, border-color 0.3s;
}
.button--filled:hover, .button--filled:focus {
  background-color: var(--color-blue-dark);
  border-color: var(--color-blue-dark);
}

.button--outline {
  background-color: transparent;
  color: var(--color-black);
  transition: border-color 0.3s, color 0.3s;
}
.button--outline:hover, .button--outline:focus {
  color: var(--color-blue-dark);
  border-color: var(--color-blue-dark);
}

.banner {
  padding-bottom: 260px;
  padding-top: 260px;
  position: relative;
  text-align: center;
}
@media (min-width: 768px) {
  .banner {
    margin-bottom: 130px;
    margin-top: 130px;
  }
}

.banner__title {
  font-size: 2rem;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .banner__title {
    font-size: 3rem;
  }
}

.banner__text {
  font-size: 1.125rem;
  margin-bottom: 33px;
}
@media (min-width: 768px) {
  .banner__text {
    font-size: 1.3125rem;
    padding-left: 30%;
    padding-right: 30%;
  }
}

.banner__blob {
  left: 3%;
  position: absolute;
  top: 30%;
  transform: scale(1.3);
  transform-origin: bottom right;
  z-index: -1;
}
@media (min-width: 768px) {
  .banner__blob {
    left: 50%;
    transform: scale(2);
    top: 50%;
  }
}

.blob-container {
  overflow-x: hidden;
  padding-bottom: 92.0245398773%;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: -1;
}

.blob {
  position: absolute;
  right: -10%;
  top: -15%;
  transform-origin: top right;
  z-index: -1;
}
@media (min-width: 768px) {
  .blob {
    transform: scale(2);
  }
}

.form-layer {
  -webkit-overflow-scrolling: touch;
  background-color: var(--color-white);
  border-radius: 4px;
  box-shadow: 0 0 15px var(--shadow);
  box-sizing: border-box;
  left: 50%;
  height: 100%;
  overflow-y: auto;
  padding: 55px 2% 21px 2%;
  position: fixed;
  top: 50%;
  transform: translate(75%, -50%);
  transition: transform 0.5s;
  width: 100%;
  z-index: 201;
}
@media (min-width: 768px) and (max-width: 959px) {
  .form-layer {
    max-height: 90vh;
    max-width: 90%;
  }
}
@media (min-width: 960px) {
  .form-layer {
    max-height: 95vh;
    max-width: 70%;
    padding-left: 5%;
    padding-right: 5%;
  }
}

.form-layer--visible {
  transform: translate(-50%, -50%);
}

.form-cover {
  background-color: var(--overlay);
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 200;
}

.form-cover--visible {
  display: block;
}

.form-header {
  text-align: center;
}

.is-required {
  color: var(--color-red);
  font-weight: 700;
}

.form {
  padding: 30px 5%;
}

@media (min-width: 768px) {
  .form__row--columns {
    display: grid;
    grid-column-gap: 5%;
    grid-template-columns: 1fr 1fr;
  }
}

.form__element {
  padding-bottom: 21px;
}

.form__label {
  display: block;
  margin-bottom: 6px;
}

.form__text {
  border: 1px solid var(--color-light-grey);
  border-radius: 2px;
  box-sizing: border-box;
  height: 35px;
  outline: none;
  padding: 9px;
  transition: border-color 0.3s;
  width: 100%;
}
.form__text:hover, .form__text:focus {
  border-color: var(--color-grey);
}

.form__text--area {
  height: auto;
  resize: none;
}

.form__help {
  display: inline-block;
  font-size: 0.75rem;
  margin-top: 6px;
}

.form__accepted {
  margin-bottom: 12px;
}

.form__actions {
  margin-top: 21px;
  text-align: center;
}

.form__actions--hidden {
  display: none;
}

.form-close {
  background: none;
  border: none;
  outline: none;
  position: absolute;
  right: 2%;
  top: 2%;
}

.form-message {
  border: 1px solid var(--color-black);
  border-radius: 4px;
  display: none;
  padding: 12px 3%;
  text-align: center;
}

.form-message--ok {
  border-color: var(--color-blue-dark);
  color: var(--color-blue-dark);
  display: block;
}

.form-message--no {
  border-color: var(--color-red);
  color: var(--color-red);
  display: block;
}