.button{
  border: 2px solid var(--color-blue);
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  font-size: 0.875rem;
  font-style: italic;
  font-weight: 700;
  outline: none;
  padding: 12px 18px;
  text-decoration: none;
  text-transform: uppercase;

  &:disabled{
    cursor: not-allowed;
    opacity: .5;
  }

  + .button{
    margin-left: 6px;
  }

  @media (min-width: 768px) {
    font-size: 0.9375rem;
    padding-left: 36px;
    padding-right: 36px;

    + .button{
      margin-left: 12px;
    }
  }
}

.button--filled{
  background-color: var(--color-blue);
  color: var(--color-white);
  transition: background-color .3s, border-color .3s;

  &:hover,
  &:focus{
    background-color: var(--color-blue-dark);
    border-color: var(--color-blue-dark);
  }
}

.button--outline{
  background-color: transparent;
  color: var(--color-black);
  transition: border-color .3s, color .3s;

  &:hover,
  &:focus{
    color: var(--color-blue-dark);
    border-color: var(--color-blue-dark);
  }
}
