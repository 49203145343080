.intro{
  border-bottom: 1px solid var(--color-light-grey);
  padding: 90px 5% 60px 5%;
  position: relative;
  text-align: center;

  @media (min-width: 768px) {
    padding: 200px 20% 140px 20%;
  }
}
