.blob-container{
  overflow-x: hidden;
  padding-bottom: ((100% * 300/326));
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: -1;
}

.blob{
  position: absolute;
  right: -10%;
  top: -15%;
  transform-origin: top right;
  z-index: -1;

  @media (min-width: 768px) {
    transform: scale(2);
  }
}
