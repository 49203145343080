.main-header{
  align-items: center;
  display: flex;
  justify-content: space-between;
  left: 0;
  padding-top: 21px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 102;

  @media (min-width: 768px) {
    padding-top: 36px;
  }
}

.main-header__logo{
  @media (max-width: 767px) {
    height: 24px;
    width: auto;
  }
}
