.main-footer{
  padding-bottom: 30px;
  padding-top: 30px;
}

.assets-credits{
  font-size: 0.8125rem;

  a{
    color: var(--color-blue-dark);
  }

  @media (min-width: 768px) {
    font-size: 0.875rem;
  }
}

.footer-menu{
  display: flex;

  @media (max-width: 767px) {
    margin-bottom: 6px;
  }
}

.footer-menu__item{
  list-style: none;
}

.footer-menu__link{
  padding-right: 18px;
}
