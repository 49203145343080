.banner{
  padding-bottom: 260px;
  padding-top: 260px;
  position: relative;
  text-align: center;

  @media (min-width: 768px) {
    margin-bottom: 130px;
    margin-top: 130px;
  }
}

.banner__title{
  font-size: 2rem;
  margin-bottom: 15px;

  @media (min-width: 768px) {
    font-size: 3rem;
  }
}

.banner__text{
  font-size: 1.125rem;
  margin-bottom: 33px;

  @media (min-width: 768px) {
    font-size: 1.3125rem;
    padding-left: 30%;
    padding-right: 30%;
  }
}

.banner__blob{
  left: 3%;
  position: absolute;
  top: 30%;
  transform: scale(1.3);
  transform-origin: bottom right;
  z-index: -1;

  @media (min-width: 768px) {
    left: 50%;
    transform: scale(2);
    top: 50%;
  }
}
